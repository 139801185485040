<section class="shieldFinanceBGWrap">
  <img src="../assets/img/shield-bg.png" class="bgImg" />
  <div class="shieldFinanceLoginWrap">
    <div class="compTxtHead mb-20">
      <img src="/assets/img/shildF.svg">
      <span class="companyNameTxt ml-20">Shield Finance</span>
    </div>
    <a class="loginBtn" [href]="microsoftLoginUrl">
      <img src="../assets/img/microsoft-fill.png" class="mr-5 windowImg" />
      Login with Microsoft
    </a>
    <span class="disclaimer">This workspace allows you to sign in with your moglix email ID</span>
  </div>
</section>