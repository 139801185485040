import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppUrls } from './constant/app.url.const';
import { OAuthGuard } from './guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { LosDetailComponent } from './los-detail/los-detail.component';
import { LosListingComponent } from './los-listing/los-listing.component';
import { RoleAdminGuard } from './guard/role.admin.guard';
import { LoginGuard } from './guard/login.guard';

const routes: Routes = [
  { path: '', redirectTo: AppUrls.LOGIN_PAGE, pathMatch: 'full' },
  {
    path: AppUrls.LOGIN_PAGE,
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: AppUrls.DASHBOARD,
    component: LosListingComponent,
    canActivate: [OAuthGuard],
  },
  {
    path: AppUrls.LEAD_DETAIL,
    component: LosDetailComponent,
  },
  {
    path: AppUrls.ADMIN,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [RoleAdminGuard],
  },

  { path: '**', redirectTo: AppUrls.LOGIN_PAGE, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
