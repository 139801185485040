import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { LoginComponent } from './login/login.component';
import { LosDetailComponent } from './los-detail/los-detail.component';
import { LosListingComponent } from './los-listing/los-listing.component';
import { SharedModule } from './shared/materialComponents';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LosListingComponent,
    LosDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
