import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppUrlEnum } from './constant/app.url.const';
import { OAuthGuard } from './guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { LosDetailComponent } from './los-detail/los-detail.component';
import { LosListingComponent } from './los-listing/los-listing.component';

const routes: Routes = [
  { path: '', redirectTo: AppUrlEnum.LOGIN_PAGE, pathMatch: 'full' },
  {
    path: AppUrlEnum.LOGIN_PAGE,
    component: LoginComponent,
    // canActivate: [LoginGuard],
  },
  {
    path: AppUrlEnum.DASHBOARD,
    component: LosListingComponent,
    canActivate: [OAuthGuard],
  },
  {
    path: AppUrlEnum.LEAD_DETAIL,
    component: LosDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
