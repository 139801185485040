import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    declarations: [

    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatTabsModule,
        MatTableModule,
        MatMenuModule,
    ],
    exports: [
        MatInputModule,
        MatTabsModule,
        MatTableModule,
        MatMenuModule       
    ],
})
 
export class SharedModule { }