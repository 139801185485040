import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppUrls } from '../constant/app.url.const';
import { OAuthTokenConst } from '../constant/oauth.const';
import { JwtService } from '../service/jwt.service';
import { OAuthService } from '../service/oauth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  microsoftLoginUrl = `${environment.oauthServerUrl}${environment.microsoftAuthPath}`;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly oauthService: OAuthService,
    private readonly jwtService: JwtService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    const accessToken = this.jwtService.getToken();
    if (accessToken && this.jwtService.isTokenValid(accessToken)) {
      this.redirectToDashboard();
      return;
    }
    this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
      const tokenId = queryParamMap.get(OAuthTokenConst.TOKEN_ID);
      if (
        tokenId &&
        (!accessToken || !this.jwtService.isTokenValid(accessToken))
      ) {
        this.oauthService
          .getTokenBuyTokenId(tokenId)
          .subscribe((oauthToken) => {
            this.oauthService.setUserStateWithToken(oauthToken);
          });
      }
    });
  }

  redirectToDashboard() {
    this.router.navigateByUrl(AppUrls.DASHBOARD);
  }
}
