import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../service/jwt.service';
import { OAuthService } from '../service/oauth.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private readonly jwtService: JwtService,
    private readonly oauthService: OAuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenWithBearerPrefix = this.jwtService.getTokenWithBearerPrefix();
    const validToken = this.jwtService.isTokenValid(
      tokenWithBearerPrefix?.split(this.jwtService.BEARER_PREFIX)[1]
    );
    if (!tokenWithBearerPrefix || !validToken) {
      this.oauthService.logout();
      return next.handle(req);
    }
    const headers = req.headers
      .set('Content-Type', 'application/json')
      .set('Authorization', tokenWithBearerPrefix);
    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
