import { Component, OnInit, ViewChild } from '@angular/core';
import { Renderer2,ElementRef } from '@angular/core';

@Component({
  selector: 'app-los-listing',
  templateUrl: './los-listing.component.html',
  styleUrls: ['./los-listing.component.scss']
})
export class LosListingComponent implements OnInit {


  displayColumns1: Array<string> = ['OrderID', 'ItemID', 'TransactionDate', 'PriceInfo', 'ProductMSN', 'ItemStatus','DeliveryStatus'];
  public dataSource: any[] = [];
  @ViewChild('sidebar') sidebar!: ElementRef;
    
  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.getPicksList();
  }

  menuClick(){
    this.sidebar.nativeElement.classList.toggle('close');
  }
  AddMenuClick(){
    this.renderer.removeClass(this.sidebar.nativeElement, 'close');                      
  }

  removeMenuClick(){
    this.renderer.addClass( this.sidebar.nativeElement, 'close');
  }

  toggleDropdpwnMenu(event: MouseEvent) { 
    const target = event.currentTarget as HTMLElement;
    const liElement:any = target.parentElement;
    liElement.classList.toggle('showMenu');
  }


  getPicksList() {  
    this.dataSource = [  
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'High','DeliveryStatus':'Pending'},
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'Low','DeliveryStatus':'Approved'},
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'Medium','DeliveryStatus':'Cancelled'},
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'High','DeliveryStatus':'Pending'},
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'Low','DeliveryStatus':'Approved'},
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'Medium','DeliveryStatus':'Cancelled'},
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'High','DeliveryStatus':'Pending'},
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'Low','DeliveryStatus':'Approved'},
        {'OrderID':'290701999', 'ItemID':'922337', 'TransactionDate':'14 May 2023', 'PriceInfo':'Total item value: ₹400', 'ProductMSN':'MSNRKREJOZY39N', 'ItemStatus':'Medium','DeliveryStatus':'Cancelled'},
    ];  
  }

}
