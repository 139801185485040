<header class="shieldFinanceHeadWrap">
  <div class="lftBlock">
    <i (click)="menuClick()" class="ri-menu-2-line hamburger-sidebar mr-10"></i>
    <a class="shieldLogo">
      <img src="/assets/img/shield.png" />
    </a>
  </div>
  <div class="rhtBlock">
    <div class="srchBoxContent">
      <i class="ri-search-line"></i>
      <input type="text" placeholder="Search" />
    </div>
    <div class="shieldUser ml-10">
      <span class="sUserName">PH</span>
    </div>
  </div>
</header>

<div
  #sidebar
  class="atom-sidebar close"
  (mouseover)="AddMenuClick()"
  (mouseout)="removeMenuClick()"
>
  <ul class="nav-links">
    <li>
      <a href="javascript:void(0)">
        <i class="ri-article-fill active"></i>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)">
        <i class="ri-scan-2-fill"></i>
      </a>
    </li>
  </ul>
  <ul class="nav-links bottomNavItem">
    <li>
      <a href="javascript:void(0)">
        <i class="ri-settings-4-fill"></i>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)">
        <i class="ri-scan-2-fill"></i>
      </a>
    </li>
  </ul>
</div>

<section class="shieldFinanceMainWrap">
  <div class="shieldAsideNavBar"></div>
  <main class="losMainArea">
    <ul>
      <li class="active">Domestic LOS</li>
    </ul>
    <mat-tab-group
      class="outline-tab"
      animationDuration="0ms"
      mat-align-tabs="start"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          All Lead
          <span class="noTag">600</span>
        </ng-template>
        <div class="srchBoxContent">
          <i class="ri-search-line"></i>
          <input type="text" placeholder="Search" />
        </div>

        <div class="mat-table-overflow custOrderHistoryListingWrap">
          <table
            mat-table
            class="mat-table-theme horizontal-overflow-width"
            [dataSource]="dataSource"
          >
            <ng-container matColumnDef="OrderID">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20"> Lead Details </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="flex flex-row">
                  <div>
                    <p class="blueTxt">Domestic-2615</p>
                    <p>Created : 19-Apr-2024</p>
                    <p>Modified : 23-Apr-2024</p>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="ItemID">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Company Details
                  <i class="ri-arrow-up-down-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">Supplier_Assigned</p>
                <p>9624533303</p>
                <p>shivam@mayankrawmint.com</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="TransactionDate">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Company Profile
                  <i class="ri-arrow-up-down-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p [matMenuTriggerFor]="matMenuCP" class="nofBuyers">
                  No. of Buyers
                  <span class="boldTxt">: 75</span>
                </p>
                <mat-menu #matMenuCP="matMenu" class="matMenuCPWrap">
                  <span class="headTxt">Buyers</span>
                  <ol>
                    <li>ABFL Pvt Ltd</li>
                    <li>samrat enterprises pvt ltd</li>
                    <li>mogli Lab India pvt ltd</li>
                    <li>credlix pvt ltd.</li>
                  </ol>
                </mat-menu>
                <p>
                  Limit Applied For
                  <span class="boldTxt">: 75000</span>
                </p>
              </td>
            </ng-container>

            <ng-container matColumnDef="PriceInfo">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Product Type
                  <i class="ri-arrow-down-s-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">SID</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="ProductMSN">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Lead Status
                  <i class="ri-arrow-down-s-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">Approved</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="ItemStatus">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Assignee Details
                  <i class="ri-arrow-down-s-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p>Aakash Mulchandani</p>
                <span class="rmTxt">RM: Deepti Singhal</span>
                <p>Assigned On <span class="boldTxt">: 22-Apr-2024</span></p>
              </td>
            </ng-container>

            <ng-container matColumnDef="DeliveryStatus" stickyEnd>
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20"> Action </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="actionIcon">
                  <i class="blueTxt mr-10 ri-file-edit-line"></i>
                  <i class="blueTxt ri-user-add-line"></i>
                </p>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayColumns1; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns1"></tr>
          </table>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          Pending
          <span class="noTag">120</span>
        </ng-template>
        <div class="srchBoxContent">
          <i class="ri-search-line"></i>
          <input type="text" placeholder="Search" />
        </div>
        <div class="input-group">
          <mat-label>label Text</mat-label>
          <mat-form-field class="mat-input" appearance="outline">
            <input matInput placeholder="Ex. Pizza" value="Sushi" />
          </mat-form-field>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          Approved
          <span class="noTag">100</span>
        </ng-template>
        <div class="srchBoxContent">
          <i class="ri-search-line"></i>
          <input type="text" placeholder="Search" />
        </div>
        Content 3
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          Rejected
          <span class="noTag">20</span>
        </ng-template>

        <div class="srchBoxContent">
          <i class="ri-search-line"></i>
          <input type="text" placeholder="Search" />
        </div>

        <div class="mat-table-overflow custOrderHistoryListingWrap">
          <table
            mat-table
            class="mat-table-theme horizontal-overflow-width"
            [dataSource]="dataSource"
          >
            <ng-container matColumnDef="OrderID">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20"> Lead Details </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="flex flex-row">
                  <div>
                    <p class="blueTxt">Domestic-2615</p>
                    <p>Created : 19-Apr-2024</p>
                    <p>Modified : 23-Apr-2024</p>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="ItemID">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Company Details
                  <i class="ri-arrow-up-down-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">Supplier_Assigned</p>
                <p>9624533303</p>
                <p>shivam@mayankrawmint.com</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="TransactionDate">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Company Profile
                  <i class="ri-arrow-up-down-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p [matMenuTriggerFor]="matMenuCP" class="nofBuyers">
                  No. of Buyers
                  <span class="boldTxt">: 75</span>
                </p>
                <mat-menu #matMenuCP="matMenu" class="matMenuCPWrap">
                  <span class="headTxt">Buyers</span>
                  <ol>
                    <li>ABFL Pvt Ltd</li>
                    <li>samrat enterprises pvt ltd</li>
                    <li>mogli Lab India pvt ltd</li>
                    <li>credlix pvt ltd.</li>
                  </ol>
                </mat-menu>
                <p>
                  Limit Applied For
                  <span class="boldTxt">: 75000</span>
                </p>
              </td>
            </ng-container>

            <ng-container matColumnDef="PriceInfo">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Product Type
                  <i class="ri-arrow-down-s-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">SID</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="ProductMSN">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Lead Status
                  <i class="ri-arrow-down-s-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">Approved</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="ItemStatus">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Assignee Details
                  <i class="ri-arrow-down-s-line"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p>Aakash Mulchandani</p>
                <span class="rmTxt">RM: Deepti Singhal</span>
                <p>Assigned On <span class="boldTxt">: 22-Apr-2024</span></p>
              </td>
            </ng-container>

            <ng-container matColumnDef="DeliveryStatus" stickyEnd>
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20"> Action </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <!-- <p class="actionIcon">
                  <i class="blueTxt mr-10 ri-file-edit-line"></i>
                  <i class="blueTxt ri-user-add-line"></i>
                </p> -->
                <button class="lmsCTA">
                  Send to LMS
                  <i class="ri-play-large-line ml-5"></i>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayColumns1; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns1"></tr>
          </table>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          Onboarded
          <span class="noTag">120</span>
        </ng-template>
        <div class="srchBoxContent">
          <i class="ri-search-line"></i>
          <input type="text" placeholder="Search" />
        </div>
        Content 3
      </mat-tab>
    </mat-tab-group>
  </main>
</section>
