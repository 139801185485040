import { Injectable } from '@angular/core';
import { StorageEnum } from '../enum/storage.enum';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor() {}

  getItemByName(name: StorageEnum): string | null {
    try {
      return localStorage.getItem(name);
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  setItemByName(name: StorageEnum, value: string): boolean {
    try {
      localStorage.setItem(name, value);
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  removeItemByName(name: StorageEnum): boolean {
    try {
      localStorage.removeItem(name);
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  clearStorage(): boolean {
    try {
      localStorage.clear();
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  }
}
