import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { JwtService } from '../service/jwt.service';
import { RoleConst } from '../constant/role.const';

export const RoleAdminGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const jwtService = inject(JwtService);

  if (jwtService.hasRole(RoleConst.ROLE_ADMIN)) return true;
  return false;
};
