import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OAuth2Token } from '../type/oauth2.token.type';
import { StorageService } from './storage.service';
import { StorageConst } from '../constant/storage.const';
import { AppUrls } from '../constant/app.url.const';

@Injectable({ providedIn: 'root' })
export class OAuthService {
  constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
    private readonly router: Router
  ) {}

  public getTokenBuyTokenId(tokenId: String): Observable<OAuth2Token> {
    const tokenByIdUrl = `${environment.oauthServerUrl}${environment.oauthTokenByIdPath}${tokenId}`;
    return this.http.get<OAuth2Token>(tokenByIdUrl);
  }

  public redirectToMicrosoftLogin(): void {
    const microsoftLoginUrl = `${environment.oauthServerUrl}${environment.microsoftAuthPath}`;
    this.router.navigateByUrl(microsoftLoginUrl);
  }

  public setUserStateWithToken(oauthToken: OAuth2Token) {
    this.storageService.setItemByName(
      StorageConst.OAUTH_TOKEN,
      oauthToken.data
    );
    this.router.navigateByUrl(AppUrls.DASHBOARD);
  }

  public logout() {
    this.storageService.clearStorage();
  }

  public logoutAndRedirectToLogin() {
    this.storageService.clearStorage();
    this.router.navigateByUrl(AppUrls.LOGIN_PAGE);
  }
}
