<section class="losLeadsDetailsWrap">
  <div class="LosDomsticHead">
    <span class="txtLosHead">
      <i class="ri-arrow-left-line mr-10"></i>
      Domestic LOS
    </span>
    <div class="nameLosTxt ml-10">Anil Shah</div>
  </div>

  <div class="losTxtLeadContWrap">
    <div class="lftTxtCon">
      <div class="commonInfoTxt mr-30">
        <p class="">Lead Summary</p>
        <p class="srcTxt">Source: <strong>SOB</strong></p>
      </div>
      <div class="commonInfoTxt">
        <p>Contact Details</p>
        <p>fixedline industrial solution</p>
        <p>+91-9390027207, info@fixedlineind.com</p>
      </div>
    </div>
    <div class="rhtTxtCon">
      <div class="commonInfoTxt">

      </div>
    </div>

    <!-- <div class="lead-summary section">
      <strong>Lead Summary</strong>
      <p>Source: <strong>SOB</strong></p>
    </div>
    <div class="contact-details section">
      <strong>Contact Details</strong>
      <div class="details">
        <p>Fixedline Industrial Solution</p>
        <p>+91-9870030297</p>
        <p>info@fixedlineind.com</p>
      </div>
    </div>
    <div class="other-details section">
      <strong>Other Details</strong>
      <div>
        <span><i class="icon">&#128269;</i> 0 Number of Buyer</span>
        <span><i class="icon">&#128212;</i> 0 Limit Required</span>
      </div>
    </div> -->
  </div>
</section>
